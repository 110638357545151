import React from "react";
import logo from "../logo.png";
import '../App.scss';
import {Link} from "react-router-dom";

function NotConnected() {


    return <>
        <div className="text-center">
            <img src={logo}
                 className="p-4" alt={"MEDIA CENTER"} />
        </div>
        <hr />
        <div className="alert alert-danger text-center"
             role="alert">
            Sorry Dude ! <strong>Accès refusé</strong> car vous n'êtes pas connecté.
        </div>
        <div className={"text-center"}>
            <Link to={"/login"}><button type="button"
                    className="btn btn-primary">Me connecter
            </button></Link></div>

    </>
        ;
}
export default NotConnected