import React, {useEffect} from 'react';

import Login from "./login/login";
import SessionContext from "./session/session";
import {
    BrowserRouter,
    Routes,
    Route, Navigate,
} from "react-router-dom";
import NotConnected from "./errors/notconnected";


function RedirectionToLogin(){
    return <Navigate to="/login" replace={true} />
}

function App() {
    let S = React.useContext(SessionContext);

    useEffect(() => {
        console.log("InitSession")
        S.InitSession();
    }, []);

  return (

      <div className="containeur align-items-center">
          <BrowserRouter>
              <Routes>
                  <Route path="/" exact={true} element={<RedirectionToLogin />} />
                  <Route path="/login" exact={true} element={<Login />} />
                  <Route path="*" element={<NotConnected />}  />
              </Routes>
          </BrowserRouter>
      </div>

  );
}

export default App;
