import React, {useContext, useState} from 'react'

const UrlStatusSession = "/api/session/status";
const Urlcsrf = "/api/session/csrf";
const CsrfHeader = "x-csrf-token";

let SessionDefaut = {
    Initialized: false,
    Connected: false,
    Logout: false,
    Expired: false,

    Username: '',

    Preferences: [],
}



const SessionContext = React.createContext();
function Session({ children }: { children: React.ReactNode }) {
    let CsrfToken = null;

    const [sessionState, setSessionState] = useState(SessionDefaut);

    let S = useContext(SessionContext)

    let InitSession = () => {
        if (sessionState.Initialized === false) {
            ReqGet(UrlStatusSession, async (response)=> {
                let json = await response.json();
                LoadSession(json.data);
            });
        }
    }

    let LogoutSession = (forced) => {
        let newSession = structuredClone(SessionDefaut);
        if(forced !== undefined){
            newSession.Expired = true;
        }else{
            newSession.Logout = true;
        }
        newSession.Initialized = true;
        setSessionState(newSession);
    }

    let LoadSession = (session) => {
        session.Initialized = true;
        session.Connected = true;
        setSessionState(session);
    }

    let IsSessionLoaded = () => {
        return sessionState.Connected;
    }

    let GetSession = () => {return sessionState;}

    let ReqGet = (url: string, callback) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': "XMLHttpRequest",
            },
            mode: 'same-origin',
            cache: "no-store",
        }).then(response => {
            if (response.ok) {
                callback(response);
            } else {
                HandleFatalError(response, url, null, callback);
            }
        });
    }


    /*
        Gestion des erreurs fatales de l'API
     */
    async function HandleFatalError(resp, url, postdata, callback) {

        if(resp.status === 401){
            // Session invalide ou expirée
            let newSession = structuredClone(SessionDefaut);
            newSession.Initialized = true;
            setSessionState(newSession);
            return;
        }

        setModalOpt({Show: true, SuperFatal: false});

        if(resp.status === 404){
            setModalOpt({Show: true, SuperFatal: true, actionBtn: false, ShowLoadingAnimation: false});


            let content = "Impossible d'executer la commande.";
            setDataModal({Titre: "Erreur 404", Content: content});
            return;
        }

        // Détection du code erreur
        let json;
        try {
            json = await resp.json();
        } catch(e) {
            // Trigger une erreur fatale
            setDataModal({Titre: "Erreur inconnue", Content: "Le système a retourné le message suivant :\n" + e.toString()});
            return;
        }

        setDataModal({Titre: "n°" + json.errorId + " - Erreur " + json.errorCode, Content: json.error+"\n\nSi le problème persiste, veuillez contacter l'administrateur en lui indiquant le code erreur n°" + json.errorId+"."});

    }

    let ReqPost = (url: string, data: string, callback, rescuecall) => {
        if (CsrfToken === null) {
            GetCsrfToken((response) => {
                if (response.ok === true) {
                    Fetcher(url, data, callback, rescuecall);
                } else {
                    callback(response);
                }

            });

        } else {
            Fetcher(url, data, callback, rescuecall);
        }
    }

    let GetCsrfToken = (callback) => {
        fetch(Urlcsrf).then(response => {
            if (response.ok) {
                CsrfToken = response.headers.get(CsrfHeader)
                callback(response);
            } else {
                callback(response);
            }
        });
    }

    let Fetcher = (url: string, data: string, callback, rescuecall) => {
        rescuecall = (typeof rescuecall !== 'undefined');
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authenticity-CSRF-Token': CsrfToken,
                'X-Requested-With': "XMLHttpRequest",
            },
            mode: 'same-origin',
            cache: "no-store",
            body: data
        }).then(response => {
            if (!response.ok && response.headers.get("x-csrf-error") === "csrf-not-validated" && !rescuecall) {
                console.log("CSRF incorrect. Réinitialisation du token");
                S.CsrfToken = null;
                ReqPost(url, data, callback, true)
            } else {
                if (rescuecall && response.headers.get("x-csrf-error") === "csrf-not-validated") {
                    console.log("La deuxième requête avec le nouveau CSRF a échoué.");
                }
                callback(response);
            }
        });
    };


    const [ModalOpt, setModalOpt] = useState({
        Show: false,
        SuperFatal: false,
        actionBtn: false,
        ShowLoadingAnimation: false,
        actionBtnTxt: "",
        CallBackBtn: null,
    });
    const handleClose = () => setModalOpt({Show: false, SuperFatal: false});
    const [dataModal, setDataModal] = useState({Titre: "Titre", Content: "Content."});



    const renderComponent = () => {
        let content = dataModal.Content.split('\n').map((data, i) => {
            return <p key={i}>{data}</p>
        });

        let BottomClose = <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}>Fermer
            </button>
        </div>

        let ActionBtn = <div className={"text-center"}><button type="button"
                                                               className="btn btn-primary"
                                                               onClick={ModalOpt.CallBackBtn}>{ModalOpt.actionBtnTxt}
        </button></div>


        let TopClose = <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>

        return <div className={"modal bg-dark" + ((ModalOpt.Show === true) ? ' d-block' : '') + " "+ ((ModalOpt.SuperFatal === false) ? 'bg-opacity-75' : '')}>
            <div className="modal-dialog">
                <div className="modal-content text-black">
                    <div className="modal-header">
                        <h5 className="modal-title">{dataModal.Titre}</h5>
                        { ((ModalOpt.SuperFatal === false) ? TopClose : '')}
                    </div>
                    <div className="modal-body">
                        {content}
                        { ModalOpt.actionBtn === true && ActionBtn}
                        { ModalOpt.ShowLoadingAnimation === true && <div className={"text-center"}><div className="spinner-grow"
                                                                                                        role="status">
                            <span className="visually-hidden">analyse en cours...</span>
                        </div></div>}

                    </div>
                    { ((ModalOpt.SuperFatal === false) ? BottomClose : '')}
                </div>
            </div>
        </div>;
    };

    let value = { ReqGet, ReqPost, GetSession, InitSession, IsSessionLoaded, LoadSession, LogoutSession };
    return  (
        <SessionContext.Provider
            value={value}
        >
            {renderComponent()}
            {children}

        </SessionContext.Provider>
    )
}

export { Session };


export default SessionContext;
