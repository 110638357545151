import React, {useEffect, useState} from "react";
import logo from "../logo.png";
import SessionContext from "../session/session";
import "../App.scss";

const UrlLoginSession = "/api/session/login";


function Login() {
    let S = React.useContext(SessionContext);


    const [Inputs, setInputs] = useState({Login: "", Passwd: ""});
    const [Message, setMessage] = useState({Text: "", level: false, inputError: false, Processing: false});

    useEffect(() => {
        let CurSessionState = S.GetSession();
        // On détecte si l'utilisateur a cliqué sur Logout
        if(CurSessionState.Logout === true){
            setMessage({Text: "Vous avez bien été déconnecté.", level: "info", inputError: false, Processing: false});
        }else if(CurSessionState.Expired === true){
            setMessage({Text: "Votre session a expiré.", level: "warning", inputError: false, Processing: false});
        }

        if (CurSessionState.Connected === true){
            let time = new Date().getTime();
            document.location.href="/?"+time;
        }
        console.log(CurSessionState);


    }, [S]);

    const submitForm = (e) => {
        e.preventDefault();
        setMessage({Text: "", level: false, inputError: false, Processing: !Message.Processing});

        if (Inputs.Login.length === 0 && Inputs.Login.length === 0) {
            setMessage({Text: <>Nom d'utilisateur et/ou mot de passe incorrect(s).<br /><small>En même temps, tu n'as rien écrit... Boulet</small></>, level: "danger", inputError: true, Processing: false});
            return
        }

        S.ReqPost(UrlLoginSession, JSON.stringify(Inputs), async function CallBackParcoursup(resp) {
            if(resp.ok === true){
                const json = await resp.json();
                console.log(json);
                if (json.status === "OK") {
                    let time = new Date().getTime();
                    document.location.href="/?"+time;

                }else{
                    setMessage({Text: "Nom d'utilisateur et/ou mot de passe incorrect(s).", level: "danger", inputError: true, Processing: false});
                }
            }
        });


    }

    const updateInput = (e) => {
        let InputsUpdated = structuredClone(Inputs);
        InputsUpdated[e.target.name] = e.target.value;
        setInputs(InputsUpdated)
        setMessage({Text: "", level: false, inputError: false, Processing: false});
    }




    return <>
        <div className="row">
            <div className="col-md-5 text-center align-self-center text-center">
                <img src={logo} alt={"Media Center"}
                     className="mainlogo"/>
            </div>


            <div className="col divcontent">

                <form onSubmit={submitForm}>

                    <div className="mb-3">
                        <label htmlFor="Login"
                               className="form-label">Nom d'utilisateur</label>
                        <input type="email"
                               className={"form-control"+((Message.inputError === true) ? ' is-invalid' : '')}
                               id="Login"
                               name="Login"
                               value={Inputs.Login}
                               onChange={updateInput}
                               placeholder="Nom d'utilisateur"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Passwd"
                               className="form-label">Mot de passe</label>
                        <input type="password"
                               className={"form-control"+((Message.inputError === true) ? ' is-invalid' : '')}
                               id="Passwd"
                               name="Passwd"
                               value={Inputs.Passwd}
                               onChange={updateInput}
                               placeholder="Mot de passe"
                        />
                    </div>
                    {Message.level !== false &&
                        <div className={"alert alert-"+Message.level}
                             role="alert">
                            {Message.Text}
                        </div>
                    }
                    <div className="mt-4 pt-2 loginbtn">
                            <button type="submit" onClick={submitForm}
                                    className={"btn btn-primary"} disabled={Message.Processing}>
                                {((Message.Processing === true) ? <> <span className="spinner-grow spinner-grow-sm"
                                                                           role="status"
                                                                           aria-hidden="true"></span> Analyse en cours...</> : 'Me connecter')}
                            </button>
                    </div>


                </form>
            </div>

        </div>
    </>
    ;
}
export default Login